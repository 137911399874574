:root {
  --background-color: #f5f5f5;
  --accent-color: #3f5ccf;
  --text-color: #333;
  --header-height: 74px;
  --row-content-padding: 50px;
  --h2-margin-top: 20px;
}

@media screen and (max-width: 768px) {
  :root {
    --row-content-padding: 20px;
  }
}

.accent {
  color: var(--accent-color);
}

* {
  box-sizing: border-box;
}

html, body {
  background-color: var(--background-color);
  color: var(--text-color);
  scroll-behavior: smooth;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (prefers-reduced-motion: reduce) {
  html, body {
    scroll-behavior: auto;
  }
}

h1, h2, h3, h4 {
  text-transform: uppercase;
  text-wrap: balance;
  font-family: Lato, sans-serif;
  font-weight: normal;
}

h2 {
  margin-top: var(--h2-margin-top);
  font-size: 21px;
}

.full-width-content {
  padding-left: var(--row-content-padding);
  padding-right: var(--row-content-padding);
  width: calc(100dvw - 20px);
  max-width: 1200px;
  margin: 0 auto;
}

section, article, article .article-section {
  scroll-margin-top: var(--header-height);
}

@media screen and (max-width: 650px) {
  .full-width-content {
    width: 100dvw;
  }
}

header {
  background-color: var(--background-color);
  border-bottom: 1px solid var(--text-color);
  height: var(--header-height);
  z-index: 10;
  display: flex;
  position: sticky;
  top: 0;
}

header a {
  color: var(--accent-color);
  text-decoration: none;
}

header a.active {
  text-decoration: underline;
}

header a svg {
  height: 1em;
  transform: translateY(-1px);
}

header h1.logo {
  font-size: 21px;
  font-weight: 100;
}

header .logo, header nav {
  text-transform: uppercase;
  color: var(--accent-color);
  font-family: Lato, sans-serif;
  text-decoration: none;
}

header .header-content {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

header nav ul {
  list-style: none;
  display: flex;
}

header nav ul li:not(:last-child) {
  padding-right: 15px;
}

header .menu-toggle {
  display: none;
}

header nav svg {
  fill: var(--accent-color);
}

@media screen and (max-width: 650px) {
  header nav {
    opacity: 0;
    top: var(--header-height);
    pointer-events: none;
    background-color: var(--background-color);
    order: 4;
    font-size: 1.1em;
    transition: all .3s ease-in-out;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  header nav.open {
    opacity: 1;
    pointer-events: all;
  }

  header nav ul {
    text-align: center;
    transform: translate(-50%, calc(-50% - var(--header-height)));
    padding: 0;
    line-height: 250%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  header nav ul li {
    padding: 0 !important;
  }

  header nav ul li a {
    color: var(--accent-color);
  }

  header nav ul li a svg {
    height: 1em;
  }

  header .menu-toggle {
    display: block;
  }

  header .menu-toggle:not(.open) .toClose, header .menu-toggle.open .toOpen {
    display: none;
  }
}

main {
  line-height: 1.4em;
}

p {
  margin-top: 15px;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  main #author .img-container {
    width: 100vmin;
    max-width: 50%;
    margin: 0 auto;
  }

  main #author .img-container img {
    width: 100%;
    margin: 10px auto;
    display: block;
    box-shadow: 5px 5px 5px 1px #0003;
  }
}

@media screen and (min-width: 601px) {
  main #author {
    gap: 20px;
    display: flex;
  }

  main #author .img-container {
    margin-top: var(--h2-margin-top);
    top: calc(var(--header-height)  + var(--h2-margin-top));
    align-self: flex-start;
    min-width: 220px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    position: sticky;
    overflow-y: auto;
  }

  main #author .img-container img {
    max-width: 90%;
    height: auto;
    box-shadow: 5px 5px 5px 1px #0003;
  }
}

article {
  border-radius: 10px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

article .article-section {
  isolation: isolate;
}

article .article-section img {
  max-width: 90%;
  margin: 10px auto;
  display: block;
  box-shadow: 5px 5px 5px 1px #0003;
}

article .article-section h3 {
  top: calc(var(--header-height));
  background: var(--background-color);
  z-index: 1;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
}

[data-role="expandable"] [data-role="title"] {
  align-items: center;
  margin-right: 0;
  padding-right: 40px;
  display: flex;
  position: relative;
}

[data-role="expandable"] [data-role="title"]:after {
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2240%22%20viewBox%3D%220%20-960%20960%20960%22%20width%3D%2240%22%3E%3Cpath%20d%3D%22M480-345%20240-585l47.333-47.333L480-438.999l192.667-192.667L720-584.333%20480-345Z%22%2F%3E%3C%2Fsvg%3E");
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  right: 0;
}

[data-role="expandable"] [data-role="content"] {
  flex-direction: column;
  margin-top: -16px;
  display: flex;
}

[data-role="expandable"].expanded [data-role="title"]:after {
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2240%22%20viewBox%3D%220%20-960%20960%20960%22%20width%3D%2240%22%3E%3Cpath%20d%3D%22M287.333-345%20240-392.333l240-240L720-393l-47.333%2047.333L480-538.334%20287.333-345Z%22%2F%3E%3C%2Fsvg%3E");
}

section[data-role="expandable"]:not(:last-child) {
  border-bottom: 1px solid var(--text-color);
}

[data-role="read_more"] {
  max-height: 4.5em;
  --chevron-down: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224%22%20viewBox%3D%220%20-960%20960%20960%22%20width%3D%2224%22%3E%3Cpath%20d%3D%22M480-345%20240-585l56-56%20184%20184%20184-184%2056%2056-240%20240Z%22%2F%3E%3C%2Fsvg%3E");
  --chevron-up: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224%22%20viewBox%3D%220%20-960%20960%20960%22%20width%3D%2224%22%3E%3Cpath%20d%3D%22m296-345-56-56%20240-240%20240%20240-56%2056-184-184-184%20184Z%22%2F%3E%3C%2Fsvg%3E");
  max-height: 4lh;
  position: relative;
  overflow: hidden;
}

[data-role="read_more"][data-expand-state="closed"] [data-role="content"] {
  -webkit-mask-image: linear-gradient(#000 1em, #0000 4em);
  mask-image: linear-gradient(#000 1em, #0000 4em);
}

[data-role="read_more"][data-expand-state="open"] {
  padding-bottom: 1.2em;
  max-height: none;
  padding-bottom: 1lh;
}

[data-role="read_more"] [data-role="expand"] button {
  color: var(--text-color);
}

[data-role="read_more"][data-expand-state="open"] [data-role="expand"] [data-role="read_more_button"], [data-role="read_more"][data-expand-state="closed"] [data-role="expand"] [data-role="read_less_button"] {
  display: none;
}

[data-role="read_more"] [data-role="expand"] {
  flex-flow: row;
  place-content: flex-end center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
}

[data-role="read_more"] [data-role="expand"] button {
  background-color: #0000;
  border: none;
  text-decoration: underline;
}

.article-section.references {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.article-section.references h3 {
  margin-top: 0;
}

.article-section.references button[data-role="open_dialog"] {
  background-color: #0000;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.article-section.references button[data-role="open_dialog"] svg {
  fill: var(--text-color);
}

dialog {
  border: 1px solid var(--text-color);
  background: var(--background-color);
  z-index: 11;
  max-width: 80%;
  max-height: 80dvh;
  padding: 50px 15px 15px;
  position: fixed;
  inset: 0;
  overflow: hidden;
  box-shadow: 5px 5px 5px 1px #0003;
}

dialog button {
  background-color: var(--background-color);
  border: none;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}

dialog .dialog_content {
  height: calc(80dvh - 65px);
  padding-right: 10px;
  overflow-y: auto;
}

dialog .dialog_content ul {
  padding-left: 0;
  list-style: none;
}

dialog .dialog_content ul li {
  margin-top: 5px;
}

@media screen and (max-width: 450px) {
  dialog {
    border-radius: 0;
    max-width: calc(100% - 20px);
    max-height: 100%;
  }

  article .article-section img {
    max-width: 100%;
  }
}

#principles ul, .reference_dialog ul {
  padding-left: 20px;
}

#principles ol, .reference_dialog ol {
  padding-left: 26px;
}

#principles li, .reference_dialog li {
  margin-bottom: 10px;
}

footer {
  color: var(--background-color);
  background-color: var(--text-color);
  text-align: center;
  font-family: sans-serif;
}

footer .license {
  padding-top: 10px;
  padding-bottom: 10px;
}

footer a {
  color: var(--background-color);
}

/*# sourceMappingURL=index.ed1319bc.css.map */
